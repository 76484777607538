const _auto = "auto"
const _default = "default"
const _pointer = "pointer"
const _wait = "wait"
const _text = "text"
const _move = "move"
const _help = "help"
const _none = "none"
const _progress = "progress"
const _cell = "cell"
const _crosshair = "crosshair"
const _alias = "alias"
const _copy = "copy"
const _grab = "grab"
const _grabbing = "grabbing"
const config = { "auto": _auto, "default": _default, "pointer": _pointer, "wait": _wait, "text": _text, "move": _move, "help": _help, "none": _none, "progress": _progress, "cell": _cell, "crosshair": _crosshair, "alias": _alias, "copy": _copy, "grab": _grab, "grabbing": _grabbing, "not-allowed": "not-allowed", "context-menu": "context-menu", "vertical-text": "vertical-text", "no-drop": "no-drop", "all-scroll": "all-scroll", "col-resize": "col-resize", "row-resize": "row-resize", "n-resize": "n-resize", "e-resize": "e-resize", "s-resize": "s-resize", "w-resize": "w-resize", "ne-resize": "ne-resize", "nw-resize": "nw-resize", "se-resize": "se-resize", "sw-resize": "sw-resize", "ew-resize": "ew-resize", "ns-resize": "ns-resize", "nesw-resize": "nesw-resize", "nwse-resize": "nwse-resize", "zoom-in": "zoom-in", "zoom-out": "zoom-out",  }
export { config as default, _auto, _default, _pointer, _wait, _text, _move, _help, _none, _progress, _cell, _crosshair, _alias, _copy, _grab, _grabbing }