<template>
  <NuxtLayout name="error">
    <div class="container flex max-w-2xl flex-col justify-center gap-6">
      <template v-if="errorCodeType === 5">
        <h1 class="font-sans text-2xl font-bold lg:text-4xl">
          Ooop! Something went wrong
        </h1>
        <p>Our technicians will be alerted.</p>
      </template>
      <template v-else>
        <h1 class="font-sans text-2xl font-bold lg:text-4xl">
          Ooop! This page is a little off the map
        </h1>
        <p>It looks like the page you're looking for isn't here.</p>
      </template>
      <p>
        Let's get you back on track &mdash; head to our home page and explore
        more.
      </p>

      <UiButton
        label="Back to home"
        theme="white"
        size="md"
        right-icon="arrow-right"
        @click="handleError"
      />

      <pre class="hidden">{{ error }}</pre>
    </div>
  </NuxtLayout>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps<{
  error: NuxtError
}>()

const errorCodeType = computed(() => Math.floor(props.error.statusCode / 100))

useHead({
  title: errorCodeType.value === 5 ? 'Error' : 'Page not found',
})

const handleError = () => clearError({ redirect: '/' })
</script>
