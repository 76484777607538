import revive_payload_client_YTGlmQWWT5 from "/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_fWYXZjzQhM from "/build/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import layout_manager_HP26iQ9iDk from "/build/packages/frontend/plugins/layout-manager.ts";
import payload_client_VTarsGAzgb from "/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/build/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ib6KnAhqrq from "/build/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/build/packages/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_l138bYv0gX from "/build/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import plugin_kpnqASteq8 from "/build/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_piAzjeEv9X from "/build/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import titles_2g9zaaxwFs from "/build/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_rjexdnU444 from "/build/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_2f4L554f7J from "/build/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_8brYH9QEed from "/build/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_muy1gU4FCL from "/build/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import sentry_client_shVUlIjFLk from "/build/packages/frontend/plugins/sentry.client.ts";
import defaults_bUDeEQ1pwN from "/build/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  _0_siteConfig_fWYXZjzQhM,
  layout_manager_HP26iQ9iDk,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_l138bYv0gX,
  plugin_kpnqASteq8,
  plugin_piAzjeEv9X,
  titles_2g9zaaxwFs,
  siteConfig_rjexdnU444,
  inferSeoMetaPlugin_2f4L554f7J,
  plugin_8brYH9QEed,
  plugin_muy1gU4FCL,
  sentry_client_shVUlIjFLk,
  defaults_bUDeEQ1pwN
]