/**
 * Middleware function that possibly redirects the user to a new path based on the redirect map.
 * @param to - The target route object.
 */

import { redirectMap } from './redirect/redirect.map'

export default defineNuxtRouteMiddleware((to) => {
  // skip middleware on client side
  if (import.meta.client) return

  // remove trailing slash and convert to lowercase
  // note - some urls in the redirect map keys originally included uppercase characters from old site
  // these are now converted to lowercase, so we need to do the same for the path just in case
  const path = to.path.toLowerCase().trim().replace(/\/$/, '')

  const redirectPath = redirectMap.get(path)
  if (redirectPath) {
    return navigateTo(redirectPath.to, { redirectCode: redirectPath.code })
  }
})
