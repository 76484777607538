/**
 * Nuxt SEO will already handle inserting tags for the basic favicon.ico and apple-touch-icon.png
 * This list adds additional favicons and meta tags that are not handled by Nuxt SEO
 * To generate again use e.g : https://realfavicongenerator.net/
 */
export const favicons = {
  link: [
    {
      rel: 'mask-icon',
      href: '/safari-pinned-tab.svg',
      color: '#328142',
      nonce: 'csp-nonce',
    },
    {
      rel: 'shortcut icon',
      href: '/favicon.ico',
      nonce: 'csp-nonce',
    },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
      nonce: 'csp-nonce',
    },
  ],
  meta: [
    { name: 'apple-mobile-web-app-title', content: 'AVJennings' },
    { name: 'application-name', content: 'AVJennings' },
    { name: 'msapplication-TileColor', content: '#00a300' },
    {
      name: 'msapplication-TileImage',
      content: '/mstile-144x144.png',
    },
    { name: 'theme-color', content: '#328142' },
  ],
}
