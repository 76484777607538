const _0 = "0deg"
const _1 = "1deg"
const _2 = "2deg"
const _3 = "3deg"
const _6 = "6deg"
const _12 = "12deg"
const _45 = "45deg"
const _90 = "90deg"
const _180 = "180deg"
const config = { "0": _0, "1": _1, "2": _2, "3": _3, "6": _6, "12": _12, "45": _45, "90": _90, "180": _180,  }
export { config as default, _0, _1, _2, _3, _6, _12, _45, _90, _180 }