const _DEFAULT = "#3b82f6"
const _white = "#FFFFFF"
const _black = "#000000"
const _transparent = "transparent"
const _current = "currentColor"
const _primary = {"500":"#00843D","800":"#0E1E16"}
const _red = "#FB7660"
const _orange = "#FF8450"
const _yellow = "#F2E815"
const _green = "#63D05A"
const _eggshell = "#85D8C8"
const _secondary = "#6c6b69"
const _tertiary = "#CCCBC8"
const _gray = {"100":"#FAF9F5","200":"#F5F4F0","300":"#E0DDD3","400":"#C7C0AA","500":"#85857C","700":"#595956","900":"#242422"}
const config = { "DEFAULT": _DEFAULT, "white": _white, "black": _black, "transparent": _transparent, "current": _current, "primary": _primary, "red": _red, "orange": _orange, "yellow": _yellow, "green": _green, "eggshell": _eggshell, "secondary": _secondary, "tertiary": _tertiary, "gray": _gray,  }
export { config as default, _DEFAULT, _white, _black, _transparent, _current, _primary, _red, _orange, _yellow, _green, _eggshell, _secondary, _tertiary, _gray }