import type {
  ContentTypeAgentStoryblok,
  ContentTypeBuilderStoryblok,
  ContentTypeCommunityStoryblok,
  ContentTypePropertyStoryblok,
  ContentTypePropertyConfigStoryblok,
  ContentTypeStateStoryblok,
  ContentTypeErpStoryblok,
  ContentTypeLandingPageStoryblok,
  ContentTypeNewsArticleStoryblok,
  ContentTypeHubStoryblok,
  MultilinkStoryblok,
} from '@/types/storyblok'
import type { ISbStoryData } from 'storyblok'

export const isContentTypeAgentStoryData = (
  agent: ISbStoryData<ContentTypeAgentStoryblok> | string | undefined,
): agent is ISbStoryData<ContentTypeAgentStoryblok> =>
  !!agent && typeof agent !== 'string'

export const findFirstAgentWithProp = (
  agents: (string | ISbStoryData<ContentTypeAgentStoryblok>)[] | undefined,
  prop: keyof ContentTypeAgentStoryblok,
) =>
  agents?.find(
    (agent) => isContentTypeAgentStoryData(agent) && agent.content[prop],
  ) as ISbStoryData<ContentTypeAgentStoryblok> | undefined

export const isContentTypeCommunityStoryblok = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  story: any,
): story is ISbStoryData<ContentTypeCommunityStoryblok> => {
  return (
    (story as ISbStoryData<ContentTypeCommunityStoryblok>)?.content
      ?.component === 'ContentTypeCommunity'
  )
}
export const isContentTypePropertyStoryblok = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  story: any,
): story is ISbStoryData<ContentTypePropertyStoryblok> => {
  return (
    (story as ISbStoryData<ContentTypePropertyStoryblok>)?.content
      ?.component === 'ContentTypeProperty'
  )
}
export const isContentTypeBuilderStoryblok = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  story: any,
): story is ISbStoryData<ContentTypeBuilderStoryblok> => {
  return (
    (story as ISbStoryData<ContentTypeBuilderStoryblok>)?.content?.component ===
    'ContentTypeBuilder'
  )
}
export const isContentTypePropertyConfigStoryblok = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  story: any,
): story is ISbStoryData<ContentTypePropertyConfigStoryblok> => {
  return (
    (story as ISbStoryData<ContentTypePropertyConfigStoryblok>)?.content
      ?.component === 'ContentTypePropertyConfig'
  )
}
export const isContentTypeErpStoryblok = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  story: any,
): story is ISbStoryData<ContentTypeErpStoryblok> => {
  return (
    (story as ISbStoryData<ContentTypeErpStoryblok>)?.content?.component ===
    'ContentTypeERP'
  )
}
export const isContentTypeStateStoryblok = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  story: any,
): story is ISbStoryData<ContentTypeStateStoryblok> => {
  return (
    (story as ISbStoryData<ContentTypeStateStoryblok>)?.content?.component ===
    'ContentTypeState'
  )
}
export const isContentTypeLandingPageStoryblok = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  story: any,
): story is ISbStoryData<ContentTypeLandingPageStoryblok> => {
  return (
    (story as ISbStoryData<ContentTypeLandingPageStoryblok>)?.content
      ?.component === 'ContentTypeLandingPage'
  )
}
export const isContentTypeNewsArticleStoryblok = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  story: any,
): story is ISbStoryData<ContentTypeNewsArticleStoryblok> => {
  return (
    (story as ISbStoryData<ContentTypeNewsArticleStoryblok>)?.content
      ?.component === 'ContentTypeNewsArticle'
  )
}
export const isContentTypeHubStoryblok = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  story: any,
): story is ISbStoryData<ContentTypeHubStoryblok> => {
  return (
    (story as ISbStoryData<ContentTypeHubStoryblok>)?.content?.component ===
    'ContentTypeHub'
  )
}
export const isStoryblokLink = (
  link: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
): link is MultilinkStoryblok => {
  return !!link?.cached_url
}
