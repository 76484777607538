<template>
  <component
    :is="icon"
    :class="{
      'c-icon--sm': size === 'sm',
      'c-icon--md': size === 'md',
      'c-icon--lg': size === 'lg',
      'c-icon--xl': size === 'xl',
      'c-icon--relative': size === 'relative',
    }"
    :title="title"
    class="c-icon"
  />
</template>

<script setup lang="ts">
const defaultIconSize: IconSize = 'base'
const props = withDefaults(
  defineProps<{
    icon: Icon
    size?: IconSize
    title?: string
  }>(),
  {
    size: defaultIconSize,
    title: undefined,
  },
)
const icon = computed(() => {
  return `svgo-${props.icon}`
})

export type PickIcon<T extends Icon> = T
export type IconSize = 'sm' | 'md' | 'base' | 'lg' | 'xl' | 'relative'
export type Icon =
  | '360-view'
  | '3d'
  | 'about-us'
  | 'agenda'
  | 'amphitheatre'
  | 'apartment'
  | 'ar'
  | 'arrow-left'
  | 'arrow-right'
  | 'art-installations'
  | 'bathroom'
  | 'bbq'
  | 'beach'
  | 'bedroom'
  | 'bench'
  | 'bin'
  | 'boat'
  | 'brochure'
  | 'bus'
  | 'calendar'
  | 'checklist'
  | 'chess'
  | 'chevron-down'
  | 'chevron-up'
  | 'childcare'
  | 'city-cbd'
  | 'clock'
  | 'close'
  | 'community'
  | 'community-garden'
  | 'cycling'
  | 'dog-park'
  | 'double-glazing'
  | 'download'
  | 'drone'
  | 'electricity'
  | 'email'
  | 'energy'
  | 'entertainment'
  | 'expand'
  | 'experience'
  | 'facing'
  | 'filter'
  | 'fish'
  | 'floorplan'
  | 'food-and-entertainment'
  | 'food-court'
  | 'fullscreen'
  | 'garage'
  | 'gas'
  | 'grid'
  | 'gym'
  | 'hamburger'
  | 'highway'
  | 'home'
  | 'home-for-sale'
  | 'home-theatre'
  | 'hospitals'
  | 'keys'
  | 'land'
  | 'land-alt'
  | 'living-room'
  | 'location'
  | 'lot-depth'
  | 'lot-size'
  | 'lot-width'
  | 'marker'
  | 'masterplan'
  | 'mobile-phone'
  | 'more'
  | 'move-in'
  | 'nbn'
  | 'park'
  | 'penthouse'
  | 'phone'
  | 'photo'
  | 'ping-pong'
  | 'plane'
  | 'play'
  | 'play-areas'
  | 'pool'
  | 'powder-room'
  | 'pro-9'
  | 'quality-control'
  | 'rumpus-room'
  | 'school'
  | 'scroll-to-top'
  | 'search'
  | 'shopping'
  | 'solar'
  | 'speech'
  | 'sports-arena'
  | 'stage-plan'
  | 'study-room'
  | 'support'
  | 'swim'
  | 'townhouse'
  | 'train'
  | 'upload'
  | 'valuation'
  | 'video'
  | 'virtual-tour'
  | 'walking'
  | 'walking-and-cycle'
  | 'water'
  | 'water-alt'
  | 'wetlands'
</script>

<style scoped lang="postcss">
.c-icon {
  @apply h-10 w-10;

  &--sm {
    @apply h-4 w-4;
  }

  &--md {
    @apply h-6 w-6;
  }

  &--lg {
    @apply h-8 w-8;
  }

  &--xl {
    @apply h-12 w-12;
  }

  &--relative {
    font-size: 1em;
    width: 1em;
    height: 1em;
  }
}
</style>
