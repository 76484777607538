const _xxs = [".75rem","1.7",".75rem","1.7",".75rem","1.7",".75rem","1.7"]
const _xs = [".875rem","1.7",".875rem","1.7",".875rem","1.7",".875rem","1.7"]
const _sm = ["1rem","1.7","1rem","1.7","1rem","1.7","1rem","1.7"]
const _base = ["1.125rem","1.5","1.125rem","1.5","1.125rem","1.5","1.125rem","1.5"]
const _md = ["1.25rem","1.5","1.25rem","1.5","1.25rem","1.5","1.25rem","1.5"]
const _lg = ["1.375rem","1.3","1.375rem","1.3","1.375rem","1.3","1.375rem","1.3"]
const _xl = ["1.5rem","1.3","1.5rem","1.3","1.5rem","1.3","1.5rem","1.3"]
const _2xl = ["2rem","1.2","2rem","1.2","2rem","1.2","2rem","1.2"]
const _3xl = ["2.625rem","1.1","2.625rem","1.1","2.625rem","1.1","2.625rem","1.1"]
const _4xl = ["3rem","1.1","3rem","1.1","3rem","1.1","3rem","1.1"]
const _5xl = ["3.625rem","1.1","3.625rem","1.1","3.625rem","1.1","3.625rem","1.1"]
const config = { "xxs": _xxs, "xs": _xs, "sm": _sm, "base": _base, "md": _md, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "4xl": _4xl, "5xl": _5xl,  }
export { config as default, _xxs, _xs, _sm, _base, _md, _lg, _xl, _2xl, _3xl, _4xl, _5xl }