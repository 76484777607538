const _1 = "1"
const _2 = "2"
const _3 = "3"
const _4 = "4"
const _5 = "5"
const _6 = "6"
const _7 = "7"
const _8 = "8"
const _9 = "9"
const _10 = "10"
const _11 = "11"
const _12 = "12"
const _first = "-9999"
const _last = "9999"
const _none = "0"
const config = { "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "11": _11, "12": _12, "first": _first, "last": _last, "none": _none,  }
export { config as default, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _first, _last, _none }