<template>
  <div
    :class="{
      'w-full': fullWidth,
    }"
  >
    <NuxtLink
      v-if="link"
      v-bind="computedDataAttributes"
      :style="customColorTheme"
      :class="classes"
      :to="linkData"
      :target="target"
      :download="isDownload || undefined"
      class="c-button"
    >
      <slot>
        <UiIcon
          v-if="leftIcon"
          :icon="leftIcon"
          :size="iconSize"
          class="c-button__icon pointer-events-none"
        />
        {{ label }}
        <UiIcon
          v-if="rightIcon"
          :icon="rightIcon"
          :size="iconSize"
          :class="{
            'c-button__icon--animate': rightIcon === 'arrow-right',
          }"
          class="c-button__icon pointer-events-none"
        />
      </slot>
    </NuxtLink>

    <button
      v-if="!link"
      v-bind="computedDataAttributes"
      :style="customColorTheme"
      :class="classes"
      class="c-button"
      :disabled="disabled"
      @click="($event) => emit('click', $event)"
    >
      <slot>
        <UiIcon
          v-if="leftIcon"
          :icon="leftIcon"
          :size="iconSize"
          class="c-button__icon pointer-events-none"
        />
        {{ label }}
        <UiIcon
          v-if="rightIcon"
          :icon="rightIcon"
          :size="iconSize"
          :class="{
            'c-button__icon--animate': rightIcon === 'arrow-right',
          }"
          class="c-button__icon pointer-events-none"
        />
      </slot>
    </button>
  </div>
</template>

<script setup lang="ts">
import type { MultilinkStoryblok } from '@/types/storyblok'
import type { Icon } from '@/components/ui/UiIcon.vue'
import type { UiButton } from './types'
import { formatLink } from '@/utils/link-utils'

const props = withDefaults(
  defineProps<{
    label: string
    link?: MultilinkStoryblok | string
    theme?: UiButton.Theme
    variant?: UiButton.Variant
    size?: UiButton.Size
    target?: '_blank' | '_self' | '_parent' | '_top'
    leftIcon?: Icon
    rightIcon?: Icon
    mobileSize?: UiButton.Size
    customColorTheme?: UiButton.CustomTheme
    fullWidthOnMobile?: boolean
    isDownload?: boolean
    fullWidth?: boolean
    disabled?: boolean
    dataAttributes?: Record<string, string | boolean | number>[]
  }>(),
  {
    link: undefined,
    theme: 'primary',
    variant: 'fill',
    size: 'lg',
    target: '_self',
    leftIcon: undefined,
    rightIcon: undefined,
    mobileSize: undefined,
    customColorTheme: undefined,
    dataAttributes: undefined,
  },
)
const emit = defineEmits<{
  click: [payload: Event]
}>()
const classes = computed(() => ({
  'c-button--primary': props.theme === 'primary',
  'c-button--light': props.theme === 'light',
  'c-button--dark': props.theme === 'dark',
  'c-button--muted': props.theme === 'muted',
  'c-button--white': props.theme === 'white',
  'c-button--filled': props.variant === 'fill',
  'c-button--outlined': props.variant === 'outline',
  'c-button--small': props.size === 'sm',
  'c-button--medium': props.size === 'md',
  'c-button--large': props.size === 'lg',
  [`c-button--mobile-size-${props.mobileSize}`]: props.mobileSize,
  'c-button--full-width-mobile': props.fullWidthOnMobile,
  'w-full': props.fullWidth,
}))
const iconSize = computed(() => (props.size === 'sm' ? 'sm' : 'md'))
const hasAnchor = computed(
  () => !!(isStoryblokLink(props.link) && props.link.anchor),
)
const url = computed<string | undefined>(() => {
  if (!props.link) {
    return
  }

  if (typeof props.link === 'string') {
    return props.link
  }

  if (isStoryblokLink(props.link)) {
    if (props.link.linktype === 'email') {
      return `mailto:${props.link.email}`
    }

    return formatLink(props.link.cached_url)
  }

  return undefined
})
const computedDataAttributes = computed(() => {
  if (!props.dataAttributes) {
    return {}
  }
  const attributes: (typeof props.dataAttributes)[0] = {}
  props.dataAttributes.forEach((attr) => {
    const [key, value] = Object.entries(attr)[0]
    attributes[`data-${key}`] = value
  })
  return attributes
})
const linkData = computed(() => {
  const { anchor } = props.link || {}

  if (isMounted.value && anchor && document.getElementById(anchor)) {
    return { hash: `#${anchor}` }
  }

  if (typeof url.value === 'string' && !hasAnchor.value) {
    return url.value
  }

  return {
    path: url.value || undefined,
    hash: hasAnchor.value && anchor ? `#${anchor}` : undefined,
  }
})
const isMounted = ref(false)

onMounted(() => {
  isMounted.value = true
})
</script>

<style scoped lang="postcss">
.c-button {
  @apply ease-out-expo rounded-4xl inline-flex items-center justify-center gap-2 border-[2px] border-transparent text-center text-sm font-medium transition-colors duration-700 lg:gap-3;

  &--primary {
    &.c-button--filled {
      @apply bg-primary-500 hover:bg-primary-800 hover:border-primary-800 text-white;
    }

    &.c-button--outlined {
      @apply border-primary-500 text-primary-500 hover:bg-primary-500 hover:text-white;
    }
  }

  &--light {
    &.c-button--filled {
      background-color: var(--custom-theme-fill-color, theme('colors.white'));
      color: var(--custom-theme-text-color, theme('colors.primary.500'));
    }

    &.c-button--outlined {
      border-color: var(--custom-theme-border-color, theme('colors.white'));
      color: var(--custom-theme-text-color, theme('colors.white'));
    }

    &:hover:not(:disabled) {
      background-color: var(
        --custom-theme-hover-fill-color,
        theme('colors.primary.500')
      );
      border-color: var(
        --custom-theme-hover-fill-color,
        theme('colors.primary.500')
      );
      color: var(--custom-theme-hover-text-color, theme('colors.white'));
    }
  }

  &--dark {
    &.c-button--filled {
      background-color: var(
        --custom-theme-fill-color,
        theme('colors.primary.800')
      );
      color: var(--custom-theme-text-color, theme('colors.white'));
    }

    &.c-button--outlined {
      border-color: var(
        --custom-theme-border-color,
        theme('colors.primary.800')
      );
      color: var(--custom-theme-text-color, theme('colors.primary.800'));
    }

    &:hover:not(:disabled) {
      background-color: var(
        --custom-theme-hover-fill-color,
        theme('colors.primary.500')
      );
      border-color: var(
        --custom-theme-hover-fill-color,
        theme('colors.primary.500')
      );
      color: var(--custom-theme-hover-text-color, theme('colors.white'));
    }
  }

  &--muted {
    &.c-button--filled {
      @apply border-gray-300 bg-gray-300 text-gray-700;
    }

    &.c-button--outlined {
      @apply border-gray-300 text-gray-700 hover:bg-gray-300 hover:text-gray-700;
    }
  }

  &--white {
    &.c-button--filled {
      @apply bg-white text-gray-900 hover:border-white hover:bg-white;
    }

    &.c-button--outlined {
      @apply border-white text-white hover:bg-white hover:text-gray-900;
    }
  }

  &--small {
    @apply px-4 py-1.5 text-xs;
  }

  &--medium {
    @apply px-5 py-2;
  }

  &--large {
    @apply px-9 py-3;
  }

  &--mobile-size-sm {
    @apply px-4 py-1 text-xs;

    &.c-button--medium {
      @apply lg:px-5 lg:py-2 lg:text-sm;
    }

    &.c-button--large {
      @apply lg:px-9 lg:py-3 lg:text-sm;
    }
  }

  &--full-width-mobile {
    @apply w-full lg:w-auto;
  }

  &__icon {
    @apply ease-out-expo transition-transform duration-700;

    &--left {
      @apply order-first;
    }

    &--right {
      @apply order-last;
    }
  }

  &:hover:not(:disabled) {
    .c-button__icon--animate {
      @apply translate-x-1 transform;
    }
  }

  &:disabled {
    @apply border-gray-300 text-gray-300;
  }
}
</style>
