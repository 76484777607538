const _1 = "1"
const _2 = "2"
const _3 = "3"
const _4 = "4"
const _5 = "5"
const _6 = "6"
const _7 = "7"
const _8 = "8"
const _9 = "9"
const _10 = "10"
const _11 = "11"
const _12 = "12"
const _auto = "auto"
const _3xs = "16rem"
const _2xs = "18rem"
const _xs = "20rem"
const _sm = "24rem"
const _md = "28rem"
const _lg = "32rem"
const _xl = "36rem"
const _2xl = "42rem"
const _3xl = "48rem"
const _4xl = "56rem"
const _5xl = "64rem"
const _6xl = "72rem"
const _7xl = "80rem"
const config = { "1": _1, "2": _2, "3": _3, "4": _4, "5": _5, "6": _6, "7": _7, "8": _8, "9": _9, "10": _10, "11": _11, "12": _12, "auto": _auto, "3xs": _3xs, "2xs": _2xs, "xs": _xs, "sm": _sm, "md": _md, "lg": _lg, "xl": _xl, "2xl": _2xl, "3xl": _3xl, "4xl": _4xl, "5xl": _5xl, "6xl": _6xl, "7xl": _7xl,  }
export { config as default, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _auto, _3xs, _2xs, _xs, _sm, _md, _lg, _xl, _2xl, _3xl, _4xl, _5xl, _6xl, _7xl }