import validate from "/build/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45redirect_45global from "/build/packages/frontend/middleware/01.redirect.global.ts";
import route_45global from "/build/packages/frontend/middleware/route.global.ts";
import manifest_45route_45rule from "/build/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45redirect_45global,
  route_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}