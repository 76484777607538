export default defineAppConfig({
  nuxtIcon: {
    // required, even if empty
    // https://github.com/nuxt/icon/issues/117
  },
  storyblok: {
    resolveRelations: [
      'BlockCommunitiesMap.state',
      'BlockCommunityList.state',
      'BlockEnquire.community',
      'BlockEnquiryForm.mappingSet',
      'BlockMap.community',
      'BlockNewsListCurated.community',
      'BlockPropertyDetails.title',
      'BlockPropertyList.community',
      'BlockPropertyListCurated.community',
      'ContentTypeCommunity.agents',
      'ContentTypeProperty.agents',
      'ContentTypeProperty.erpProperty',
      'ContentTypeProperty.propertyConfig',
      'ContentTypePropertyConfig.builder',
    ],
  },
})
