const _busy = "busy=\"true\""
const _checked = "checked=\"true\""
const _disabled = "disabled=\"true\""
const _expanded = "expanded=\"true\""
const _hidden = "hidden=\"true\""
const _pressed = "pressed=\"true\""
const _readonly = "readonly=\"true\""
const _required = "required=\"true\""
const _selected = "selected=\"true\""
const config = { "busy": _busy, "checked": _checked, "disabled": _disabled, "expanded": _expanded, "hidden": _hidden, "pressed": _pressed, "readonly": _readonly, "required": _required, "selected": _selected,  }
export { config as default, _busy, _checked, _disabled, _expanded, _hidden, _pressed, _readonly, _required, _selected }