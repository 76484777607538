import { useToggle } from '@vueuse/core'
import { toggleBodyScroll } from '@/utils/common-utils'

export const useUIStore = defineStore('ui', () => {
  const [isHamburgerMenuOpen, toggleHamburgerMenu] = useToggle()
  const [isPhotoGalleryVisible, togglePhotoGallery] = useToggle()
  const [isVideoGalleryVisible, toggleVideoGallery] = useToggle()
  const [isAerialMapVisible, toggleAerialMap] = useToggle()
  const [isPropertyFiltersVisible, togglePropertyFilters] = useToggle()

  watch(
    [
      isHamburgerMenuOpen,
      isPhotoGalleryVisible,
      isVideoGalleryVisible,
      isAerialMapVisible,
      isPropertyFiltersVisible,
    ],
    () => {
      const isVisible = [
        isHamburgerMenuOpen,
        isPhotoGalleryVisible,
        isVideoGalleryVisible,
        isAerialMapVisible,
        isPropertyFiltersVisible,
      ].some((value) => value.value)

      toggleBodyScroll(isVisible)
    },
  )

  function reset(): void {
    toggleBodyScroll(false)
    toggleHamburgerMenu(false)
    toggleAerialMap(false)
    togglePhotoGallery(false)
    toggleVideoGallery(false)
    togglePropertyFilters(false)
  }

  return {
    isAerialMapVisible,
    isHamburgerMenuOpen,
    isPhotoGalleryVisible,
    isPropertyFiltersVisible,
    isVideoGalleryVisible,
    reset,
    toggleAerialMap,
    toggleHamburgerMenu,
    togglePhotoGallery,
    togglePropertyFilters,
    toggleVideoGallery,
  }
})
