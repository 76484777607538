import type { BlockNavigationMenuItemStoryblok } from '@/types/storyblok'

export function stripPx(value: `${number}px`): number {
  return parseInt(value.replace('px', ''))
}

export function toggleBodyScroll(state: boolean): void {
  if (!import.meta.client) {
    return
  }

  document.body.classList.toggle('no-scrolling', state)
}

export function removeMobileOnlyMenuItems(
  menuItems?: BlockNavigationMenuItemStoryblok[],
): BlockNavigationMenuItemStoryblok[] | undefined {
  return menuItems?.filter((menuItem) => !menuItem.onlyShowOnMobile)
}

export function truncate(
  input: string,
  maxLength: number,
  append = '...',
): string {
  return input.length <= maxLength ? input : input.slice(0, maxLength) + append
}

export function normaliseText(label: string): string {
  return label
    .toLowerCase() // Convert to lowercase
    .replace(/[^a-z0-9\s]/g, '') // Remove special characters
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .trim() // Remove any leading or trailing spaces
}

export function formatToCurrencyShort(value: string | number): string {
  const num = typeof value === 'string' ? parseFloat(value) : value

  if (isNaN(num)) {
    throw new Error('Invalid number input')
  }

  if (num >= 1_000_000) {
    return `$${(num / 1_000_000).toFixed(1).replace(/\.0$/, '')}m`
  } else if (num >= 1_000) {
    return `$${(num / 1_000).toFixed(1).replace(/\.0$/, '')}k`
  } else {
    return `$${num.toString()}`
  }
}

export function remToPx(remString: string): number {
  const remValue = parseFloat(remString)
  const rootFontSize = 16
  return remValue * rootFontSize
}

export function chunkArray<T>(array: T[], chunkSize: number): T[][] {
  const chunks: T[][] = []

  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize))
  }

  return chunks
}

export function roundDecimal(size: number, max: number = 0) {
  return new Intl.NumberFormat('en-AU', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: max,
  }).format(size)
}
