import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const {
    public: { sentry },
  } = useRuntimeConfig()
  const { url: siteUrl } = useSiteConfig()

  if (!sentry.dsn) {
    console.warn('Sentry DSN is missing, skipping Sentry initialization')
    return
  }

  console.log('Initializing Sentry', sentry)

  const client = Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    environment: sentry.environment,
    release: sentry.release,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
      // Filter out errors from third-party scripts
      // https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-thirdpartyerrorfilterintegration
      Sentry.thirdPartyErrorFilterIntegration({
        // must match `applicationKey` set in nuxt.config.ts
        filterKeys: ['avjennings-website'],
        behaviour: 'drop-error-if-contains-third-party-frames',
      }),
    ],
    // set the transaction name to the current route path, otherwise all we get is 'slug' from the route name
    beforeSendTransaction(event) {
      event.transaction = router.currentRoute.value.fullPath
      return event
    },

    allowUrls: [siteUrl],

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', 'https://avjevnnings.localdev.me'],

    tracesSampleRate: parseFloat(sentry.tracesSampleRate || '0.1'),
    replaysSessionSampleRate: parseFloat(sentry.replaySampleRate || '0.1'),
    replaysOnErrorSampleRate: parseFloat(sentry.errorReplaySampleRate || '1'),
  })

  // Add the Sentry instance to the context, so we can make calls to it from anywhere in the app if needed
  return {
    provide: {
      sentry: client,
    },
  }
})
