import { minimatch } from 'minimatch'
/******
 * This plugin will set the layout of the page based on the route (or potentially other conditions later?)
 */
export default defineNuxtPlugin({
  name: 'layout-manager',
  // not sure this is needed? works for now...
  enforce: 'pre', // or 'post'
  async setup() {
    // Route middleware
    const matchLayout = (path: string) => {
      // console.info('layout-manager:matchLayout : path', path)
      const layout = minimatch(path, '/communities/*/*{,/,*/*/**}')
        ? 'community'
        : 'default'
      // console.info('layout-manager:matchLayout : layout', layout)
      setPageLayout(layout)
    }

    // check on ssr
    matchLayout(useRoute().path)

    // check on client navigation
    addRouteMiddleware((to) => {
      matchLayout(to.path)
    })
  },
})
