export function isExternalLink(link?: string): boolean {
  return ['http:', 'https:', 'tel:', 'mailto:'].some(
    (el) => link?.startsWith(el) ?? false,
  )
}

export function formatLink(link?: string): string | undefined {
  return !link ? undefined : isExternalLink(link) ? link : `/${link}`
}

export function isCurrentPage(url?: string, currentPageUrl?: string): boolean {
  return url === currentPageUrl || false
}
