<template>
  <UiPageLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
// add additional favicons to the head
useHead(favicons)
</script>
