<template>
  <NuxtLoadingIndicator :color="loadingIndicatorColor" />
</template>

<script setup lang="ts">
import { theme } from '#tailwind-config'
const { communityContent } = storeToRefs(useCurrentStoryStore())

const loadingIndicatorColor = computed(() => {
  return (
    communityContent.value?.backgroundDark?.color || theme.colors.primary[500]
  )
})
</script>
