/**
 * A promise that can be resolved or rejected from outside later
 *
 * @example
 * const deferred = new DeferredPromise<number>()
 * deferred.promise.then(value => console.log(value))
 * deferred.resolve(42)
 */
export default class DeferredPromise<T = unknown> {
  private _promise: Promise<T>
  private _resolve: (value: T | PromiseLike<T>) => void = () => {}
  private _reject: (reason?: unknown) => void = () => {}
  constructor() {
    this._promise = new Promise<T>((resolve, reject) => {
      this._resolve = resolve
      this._reject = reject
    })
  }

  get promise() {
    return this._promise
  }
  resolve(value: T | PromiseLike<T>) {
    this._resolve(value)
  }
  reject(reason?: unknown) {
    this._reject(reason)
  }
}
