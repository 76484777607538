import { default as _91_46_46_46slug_93whXb6acSl8Meta } from "/build/packages/frontend/pages/[...slug].vue?macro=true";
import { default as design_45systemHwe2Q4sZ5pMeta } from "/build/packages/frontend/pages/design-system.vue?macro=true";
import { default as component_45stubqzOyA0WOlZMeta } from "/build/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubqzOyA0WOlZ } from "/build/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/build/packages/frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "design-system",
    path: "/design-system",
    component: () => import("/build/packages/frontend/pages/design-system.vue").then(m => m.default || m)
  },
  {
    name: component_45stubqzOyA0WOlZMeta?.name,
    path: "/home",
    component: component_45stubqzOyA0WOlZ
  }
]